#cta-home{
  margin-top: 40px;
  @media #{$undermd} {
    margin-top: 0px;
  }

  .box-testo{
    @media #{$underdesktop} {
      margin-right: 40px;
    }
    @media #{$undermd} {
      margin: 60px 40px;
    }
  }
}
