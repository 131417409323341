/******** Colori *********/
/******** Basic *******/
$black: #000;
$white: #FFF; // testi
$orange: #e38c31; // Titoli ed elementi grafici
$darkBlue: #00c4eb; // Bottone
$blue: #009cdd; // Bottone
$grey: #efefef;

//corsi Colori
$green: #56b031;
$blueCourse: #007dbd;
$yellow: #fdc300;
$pink: #ea4693;
$blackCourse: #030304;
$red: #d10b10;

/*** Risoluzioni per mobile ***/
$small-phone: "(max-width: 575px)";
$landscape-phone: "(min-width: 576px) and (max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 991px)";
$undertablet: "(max-width: 767px)";
$desktop: "(min-width: 992px) and (max-width: 1199px)";
$underdesktop: "(max-width: 1199px)";
$underxl: "(max-width: 1599px)";
$desktop-xl: "(min-width: 1299px) and (max-width: 1599px)";
$under-desktop-xl: "(min-width: 992px) and (max-width: 1299px)";
$tablet-landscape: "(min-width: 992px) and (max-width: 1024px)";
$undermd: "(max-width: 991px)";
$extra-desktop: "(min-width: 1200px)";
$ultra-desktop: "(min-width: 1600px)";

//FONT
//font-family: 'Assistant', sans-sans-serif; ---> MEDIUM – Titoli Header LIGHT -  tutti Sottotitoli e cta EXTRALIGHT -  tutti Paragrafi
//font-family: 'Assistant', sans-serif; ---> Titoli paragrafi tranne Header

/*** Mixin ***/
@mixin rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  transform: rotate($rotate);
}


@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin box-shadow($boxshadow) {
  -webkit-bbox-shadow: $boxshadow;
  -moz-box-shadow: $boxshadow;
  -ms-box-shadow: $boxshadow;
  box-shadow: $boxshadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}


/******** General ********/
.container-fluid{
  padding: 0;
}
body {
  font-family: 'Assistant', sans-serif;
  font-size: 16px;
  font-weight: 300;
  overflow-x: hidden;
}

p {
  font-size: 19px;
  font-weight: 300;
  line-height: 29px;
  font-family: 'Assistant', sans-serif;
}

a{
  color: black;
  font-weight: 600;
  font-size: 18px;
  text-decoration: underline;
  font-family: 'Assistant', sans-serif;
  @include transition(all 0s ease 0s);
}
section{
  padding: 0px 0;
}

a:hover,  a.active, a:focus{
  color: $black;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}

button:hover, h1:hover, h2:hover, h3:hover,
button:focus, h1:focus, h2:focus, h3:focus {
  text-decoration: none;
  outline: 0;
  @include transition(all 0s ease 0s);
}

button{
  padding: 15px 30px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: grey;
  color: black;
  font-size: 18px;
  border: none;
  text-decoration: none;
}

button:focus, button:hover {
  @include transition(all 0s ease 0s);
  color: $black;
}

b, strong {
    font-weight: 600;
}

em {
}

i{
  line-height: 30px;
}

small{
  font-size: 16px;
}

.h1, .h2, .h3, h1, h2, h3, h4 {
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 50px;
    font-family: 'Assistant', sans-serif;

    @media #{$small-phone} {
      line-height: 40px;
    }
}

h1{
  font-size: 45px;

}
h2{
  font-size: 35px;
  line-height: 40px;
  font-weight: normal;
}

hr {
    margin: 30px 0;
    border: 1px solid #dfdfdf;
}

blockquote{
  padding: 10px 0px;
  margin: 0 0 20px;
  font-size: 40px;
  font-weight: 600;
  border-left: 0px;
  font-family: 'Assistant', sans-serif;
  text-align: center;
}

*:focus,  *:active{
  outline: 0 !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

li{
    font-family: 'Assistant', sans-serif;
    font-weight: 300;
}


#sb-site{
  min-height: 1000px !important;
}


.centered {
  text-align: center;

  .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3 {
    float: none;
    display: inline-block;

    @media #{$underdesktop} {
      float: left;
    }
  }
  .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3 {
    float: none;
    display: inline-block;

    @media #{$underdesktop} {
      float: left;
    }
  }
}

.vcenter {
    display: flex;
    align-items: center;
    @media #{$undermd} {
      display: inherit;
      align-items: baseline;
    }
}

.container{
  @media #{$ultra-desktop} {
    width: 1570px;
  }
  @media #{$desktop-xl} {
    width: 1270px;
  }
}

/******** Integrazione di bootstrap *******/
.col-xs-offset-right-12 {
  margin-right: 100%;
}
.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}
.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}
.col-xs-offset-right-9 {
  margin-right: 75%;
}
.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}
.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}
.col-xs-offset-right-6 {
  margin-right: 50%;
}
.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}
.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}
.col-xs-offset-right-3 {
  margin-right: 25%;
}
.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}
.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}
.col-xs-offset-right-0 {
  margin-right: 0;
}

/******* Percorso ******/

#breadcrumbs{
    list-style:none;
    margin:10px 0;
    overflow:hidden;
}

#breadcrumbs li{
    display:inline-block;
    vertical-align:middle;
    margin-right:5px;
}

#breadcrumbs .separator{
    font-size:18px;
    font-weight:100;
    color: $black;
    content: "/";
}

.contenuto{
  text-align: center;
  position: relative;
  z-index: 0;
}

/******* Main *******/

#main {
    padding: 0px 0;
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-position: top center;
}

/****** Home ******/

/****** Categorie *******/

/****** Articoli *******/
figure{
  margin: 0 !important;
}
figure.effect-one img {
  @include transition(all 0.35s);
	-webkit-transform: translate3d(0,0, 0);
	transform: translate3d(0,0,0);
  width: 240px;
  margin: 0 auto;
}

figure.effect-one figcaption {
}

figure.effect-one figcaption > div {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 15px;
	width: 100%;
	height: 25%;
}

figure.effect-one:hover figcaption > div {
  height: 70%;
  @include transition(height 0.2s);
}

figure.effect-one h3,
figure.effect-one p {

	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
}

figure.effect-one h3 {
  @include transition(-webkit-transform 0.35s);
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-one p {
	color: #4d4d4c;
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

figure.effect-one:hover {
  border: 3px solid rgba(255,224,46,1);
  -webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}

figure.effect-one:hover p {
	opacity: 1;
}

figure.effect-one:hover img {
  width: 130px;
	-webkit-transform: translate3d(0,-40px,0);
	transform: translate3d(0,-40px,0);
}

figure.effect-one:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}


/********** Contatti ************/

/***** Pagina Cerca ******/
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #e2343e;
    border-color: #e2343e;
    color: #fff;
    cursor: default;
    z-index: 3;
}

.search .nav li .active a {
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5em;
    padding: 5px 15px 3px;
    text-decoration: none;
    text-transform: none;
}

.search .nav li a {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5em;
    padding: 5px 15px 3px;
    text-decoration: none;
    text-transform: none;
}

/******** COOKIE *********/
.cc-banner .cc-message{
  font-size: 14px;
  font-family: 'Assistant', sans-serif;
  font-weight: 600;

}

.cc-banner a.cc-btn{
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
}
.cc-banner a.cc-btn:hover{
  font-weight: 200;
  text-decoration: none !important;
  font-size: 14px;
}

/******** 404 ********/
.error-404{
  .page-header{
    margin-top: 50px;
    border-bottom: 1px solid transparent;
  }

  text-align: center;
  span{
    color: #000;
    text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135;
    font-family: 'Arial Black', Gadget, sans-sans-serif;
    font-size:180px;
  }
  h1{
    color: $black;
    font-size: 40px;
    font-family: 'Assistant', sans-serif;
  }

  p{
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    color: $black;
    bottom: 40px;
  }

  .page-content{
    margin-bottom: 100px;
  }

  a{

    position: relative;
    background: $black;
    color: $white;
    padding: 5px 10px;
    text-decoration: none;
    font-weight: 200;
  }
}

/********* Iubenda **********/
#iubenda {
    background-color: #eee;
}
a { color: green; }
.center {
    margin-top: 100px;
}

/* cookie banner related styles below */

/***************************************************************************
// * Iubenda Cookie Solution                                               *
// *                                                                       *
// * (c) 2015 Iubenda srl <info@iubenda.com>                               *
// * All Rights Reserved.                                                  *
// *                                                                       *
// *************************************************************************
//
// CHANGELOG
// 0.0.1
// - first implementation of customizable css
*/

/*  GENERAL CLASS */
#iubenda-cs-banner{
    /* ------ IMPORTANT NOTICE: don't change these values as
            they may prevent the banner from properly showing. Some of these lines,
            moreover (like z-index) will overwrite the configuration initialized
            by the script you paste in your pages. To show the banner at the bottom
            you can switch to "bottom: 0px !important;". Don't forget to put "slideDown"
            to "false".
     */
    bottom: 0px !important;
    left: 0px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999998 !important;
    background-color: black;
    /* ----------------- */
}

/*  CONTENT CLASS */
.iubenda-cs-content{
    display:block;
    margin:0 auto;
    padding: 20px;
    width:auto;
    font-family: Helvetica,Arial,FreeSans,sans-serif;
    font-size: 14px;
    background: #000;
    color: #fff;
}
.iubenda-banner-content{
  p{
    font-size: 12px;
    line-height: 20px;
  }
}

.iubenda-cs-rationale{
    max-width: 900px; position:relative; margin: 0 auto;
}

/*   STYLE FOR TEXT INTERNAL BANNER */
.iubenda-banner-content > p {
    font-family: Helvetica,Arial,FreeSans,sans-serif;
    line-height: 1.5;
}

/*  CLOSE BUTTON. NOTICE: IMAGE FOR BUTTON NOT IMPLEMENTED YET */
.iubenda-cs-close-btn{
    margin:0;
    color: #fff;
    text-decoration:none;
    font-size:14px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
}

/*  STYLE FOR TEXT LINK TO PRIVACY POLICY */
.iubenda-cs-cookie-policy-lnk{
    text-decoration: underline;
    color: #fff;
    font-size: 14px;
    font-weight: 900;
}
