/******** HOME PAGE *********/
#musicart{
  overflow-x: hidden;
}
#header-home{
  position: relative;
  top:-90px;
  margin-bottom: -100px;
  @media #{$undertablet} {
    top: 0px;
  }
  .box-titolo{
    top:-90px;
    position: relative;
    .titolo{
      position: absolute;
      z-index: 1;
      display: inline-block;
      left: 50%;
      @include transform(translateX(-50%));

      @media #{$undermd} {
        left: 0%;
        @include transform(translateX(0%));
        margin-left: 40px;

      }
    }
  }

  .div-scroll {
    position: absolute;
    margin-left: 0px;
    margin-top: 0px;
    bottom: 200px;
    @media #{$desktop-xl} {
      bottom: 150px;
    }
    @media #{$under-desktop-xl} {
      bottom: 150px;
    }
    @media #{$underdesktop} {
      bottom: 80px;
    }
    @media #{$undermd} {
      display: none;
    }
  }

  .box{
    position: relative;

  }

  .box-testo{
    margin-left: 60px;
    @media #{$underdesktop} {
      margin-left: 0px;
      margin-right: 20px;
    }
    @media #{$undermd} {
      margin-left: 0px;
      margin-right: 0px;
      margin: 80px 40px;
      padding-bottom: 60px;
    }

    .testo{
      h5{
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 800;
      }

      p{
        font-size: 25px;
      }
    }
  }
}
