#block_corsibatteria{
  padding: 60px 0;
  .lens{
    position: relative;
    left: 45px;
  }

  .slick-track{
    left: 250px;
    @media #{$underxl} {
      left: 150px;
      margin-top: 40px;
    }
    @media #{$undermd} {
      left: 50px;
      margin-top: 40px;
    }
    @media #{$undertablet} {
      left: 20px;
      margin-top: 40px;
    }
    @media #{$small-phone} {
      left: 0px;
      margin-top: 40px;
    }
  }

  .slick-active{
  }

  .slick-cloned{
    opacity: 0;
  }

  //slider corsi
  .lens:before{
    content: url("../images/lente.svg");
    display:block;
    width:40px;
    height:40px;
    margin:10px 5px 0 10px;
    @media #{$underdesktop} {
      width:30px;
      height:30px;
    }
  }

  .corso:hover{
    .lens:before{
      content: url("../images/lente_hover.svg");
      display:block;
      width:40px;
      height:40px;
      margin:10px 5px 0 10px;
      @media #{$underdesktop} {
        width:30px;
        height:30px;
      }
    }

    .titoli-slider{
      color: $orange !important;
    }
    .sottotitoli-slider{
      color: $orange !important;
    }
  }

  .slider-corsi{
    .even{
      img{
        padding:0 40px;
        margin-top: 60px;
      }
      .titolo{
        position: relative;
        margin-top: -50px;
        padding-left: 40px;
        @media #{$underxl} {
          margin-top: -20px;
          padding-left: 20px;
        }
      }
    }

    .odd{
      img{
        padding:0 40px;
        margin-top: 0px;
      }
      .titolo{
        position: relative;
        margin-top: -50px;
        padding-left: 40px;

        @media #{$underxl} {
          margin-top: -20px;
          padding-left: 20px;
        }
      }
    }
  }
}

//POPUP Corsi
.modal-body{
  padding: 0;
}

.modal-dialog {
  width: 100%;
  height: 100% !important;
  margin: 0;
  @media #{$undermd} {
    width: 100%;
  }
  @media #{$undertablet} {
    width: 100%;
  }
}

.modal-content{
  width: 1024px;
  background: $white;
  background-image: url("../images/texture_sfondo.png");
  color: $white;
  position: relative;
  border:0;
  border-radius: 0;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%,-50%));

  @media #{$underdesktop} {
    width: 800px;
  }
  @media #{$undermd} {
    width: 600px;
    padding: 10px 0px 40px;
  }
  @media #{$undertablet} {
    width: 350px;
  }

}

.modal-corso{
  .contenuto-corso{
    min-height: 200px;
    overflow-y: auto;
    padding-right: 30px;
    color: $black;
    background: transparent;
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    @media #{$underdesktop} {
      font-size: 16px;
      line-height: 20px;
      min-height: 120px;
    }
  }

  ul{
    li{
      color: $black;
      font-size: 18px;
      font-weight: 300;
      line-height: 25px;
      @media #{$underdesktop} {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.button.close{
  right: 10px;
  top:10px;
  position: relative;
  background: transparent;
  opacity: 1;
  z-index: 1 !important;
  background: $black !important;

  padding: 5px 10px !important;
  color: $white;
  border-radius: 10px;
  border-radius: 100px !important;
}

.close{
  right: 10px;
  top:10px;
  position: relative;
  background: transparent;
  opacity: 1;
  z-index: 1 !important;
  background: $black !important;

  padding: 6px 10px 8px !important;
  color: $white;
  border-radius: 120px !important;
  font-weight: 200;
  font-size: 20px;
}
.contenuti{
  padding: 40px;
  position: relative;
  @media #{$underdesktop} {
    padding: 25px 40px;
  }

  p,em{
    @media #{$underdesktop} {
      font-size: 16px;
      line-height: 20px;
    }
  }

  strong{
    font-weight: 600;
  }

  li{
    font-size: 19px;

  }

  .titoli-popup{
    font-size: 50px;
    font-weight: 800;
    line-height: 55px;
    margin-left: 30px;
    text-transform: uppercase;
    color: $black;
    @media #{$underdesktop} {
      font-size: 35px;
      line-height: 40px;
    }
    @media #{$undertablet} {
      margin-left: 0px;
    }
  }

  .titoli-popup:before{
    position: absolute;
    content: "";
    height: 3px;
    width: 30px;
    background: $black;
    top: 50px;
    left: 0px;
    @media #{$undertablet} {
      display: none;
    }
  }

  .sottotitoli-popup{
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 30px;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    @media #{$undertablet} {
      margin-left: 0px;
    }
  }

  p{
    color: $black;
  }
  .cta-button{
    background: $blue;
    margin-left: 30px;

    @media #{$undertablet} {
      margin-left: 0px !important;
    }
  }
}

.scrollbar
{
  margin-left: 30px;
  float: left;
  height: 200px;
  background: $white;
  overflow-y: scroll;
  margin-bottom: 25px;
  @media #{$underdesktop} {
    height: 120px;
  }
  @media #{$undertablet} {
    margin-left: 0px !important;
    height: 350px !important;
  }
}
.verde{
  .titoli-popup{
    color: $green;
  }
  .titoli-popup:before{
    background: $green;
  }
  .sottotitoli-popup{
    color: $green;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(86,176,49,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }
  .scrollbar
  {
    margin-left: 30px;
    float: left;
    height: 200px;
    background: $white;
    overflow-y: scroll;
    margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 120px;
    }
    @media #{$undertablet} {
      margin-left: 0px !important;
      height: 300px;
    }
  }


  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $green;
  }
}

.verde{
  .titoli-popup{
    color: $green;
  }
  .titoli-popup:before{
    background: $green;
  }
  .sottotitoli-popup{
    color: $green;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(86,176,49,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 300px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $green;
  }
}

.yellow{
  .titoli-popup{
    color: $yellow;
  }
  .titoli-popup:before{
    background: $yellow;
  }
  .sottotitoli-popup{
    color: $yellow;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(253,195,0,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 300px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $yellow;
  }
}

.blue{
  .titoli-popup{
    color: $blueCourse;
  }
  .titoli-popup:before{
    background: $blueCourse;
  }
  .sottotitoli-popup{
    color: $blueCourse;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(0,125,189,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 300px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $blueCourse;
  }
}

.red{
  .titoli-popup{
    color: $red;
  }
  .titoli-popup:before{
    background: $red;
  }
  .sottotitoli-popup{
    color: $red;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(209,11,16,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 300px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $red;
  }
}

.black{
  .titoli-popup{
    color: $blackCourse;
  }
  .titoli-popup:before{
    background: $blackCourse;
  }
  .sottotitoli-popup{
    color: $blackCourse;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(3,3,4,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 300px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $blackCourse;
  }
}

.pink{
  .titoli-popup{
    color: $pink;
  }
  .titoli-popup:before{
    background: $pink;
  }
  .sottotitoli-popup{
    color: $pink;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(234,70,147,.5);

    img{
      position: relative;
      z-index:-1;
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      height: 300px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $pink;
  }
}
