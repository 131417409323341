#block_percorsiformativi{
  padding: 60px 0;
  .lens{
    position: relative;
    left: 45px;
  }

  .slick-track{
    left: 250px;
    @media #{$underxl} {
      left: 150px;
      margin-top: 40px;
    }
    @media #{$undermd} {
      left: 50px;
      margin-top: 40px;
    }
    @media #{$undertablet} {
      left: 0px;
      margin-top: 40px;
    }
  }

  .slick-active{
  }
  .slick-cloned{
    opacity: 0;
  }

  //slider corsi
  .lens:before{
    content: url("../images/lente.svg");
    display:block;
    width:40px;
    height:40px;
    margin:10px 5px 0 10px;
    @media #{$underdesktop} {
      width:30px;
      height:30px;
    }
  }

  .corso:hover{
    .lens:before{
      content: url("../images/lente_hover.svg");
      display:block;
      width:40px;
      height:40px;
      margin:10px 5px 0 10px;
      @media #{$underdesktop} {
        width:30px;
        height:30px;
      }
    }
  }

  .slider-corsi{
    .even{
      img{
        padding:0 40px;
        margin-top: 60px;
      }
      .titolo{
        position: relative;
        margin-top: 0px;
        padding-left: 40px;
        @media #{$underxl} {
          margin-top: 0px;
          padding-left: 20px;
        }
      }
    }

    .odd{
      img{
        padding:0 40px;
        margin-top: 0px;
      }
      .titolo{
        position: relative;
        margin-top: 0px;
        padding-left: 40px;
        @media #{$underxl} {
          margin-top: 0px;
          padding-left: 20px;
        }
      }
    }
  }
}

.percorsi{
  .titoli-popup{
    color: $blackCourse;
  }
  .titoli-popup:before{
    background: $blackCourse;
  }
  .sottotitoli-popup{
    color: $blackCourse;
  }

  .image{
    height: 100%;
    overflow: hidden;
    position: relative;
    height: 500px;
    background: $white;
    @media #{$underdesktop} {
      height: 450px;
    }


    img{
      position: relative;
      z-index:1;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .scrollbar
  {
  	margin-left: 30px;
  	float: left;
  	height: 200px;
  	background: transparent;
  	overflow-y: scroll;
  	margin-bottom: 25px;
    @media #{$underdesktop} {
      margin-bottom:0px;
    }
  }

  .scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  .scrollbar::-webkit-scrollbar
  {
  	width: 12px;
  	background-color: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: $blackCourse;
  }
}
