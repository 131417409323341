.site-header{
  margin-bottom: 0 !important;
}

.cover{
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
}

figure#homeheader {
  position: relative;
  height: 100vh;
  overflow: hidden;

  img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -10;
    opacity: 1;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      img {
        height: inherit;
        width: inherit;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: -10;
      }
   }

   @supports (-ms-ime-align:auto) {
     /* IE Edge 12+ CSS styles go here */
      img {
        height: inherit;
        width: inherit;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: -10;
      }
   }



  figcaption {
    position: absolute;
    top: 50%;
    @include transform(translate(-50%,-50%));
    color: $white;
    left: 50%;
    @media #{$desktop-xl} {
      left: 100px;
    }
    @media #{$under-desktop-xl} {
      left: 100px;
    }
    @media #{$desktop} {
      left: 50px;
    }
    @media #{$underdesktop} {
      left: 50%;
      @include transform(translate(-50%,-50%));
    }
    @media #{$undermd} {
      width: 100%;
    }
    @media #{$undertablet} {
      left: 0;
      @include transform(translate(0%,-50%));
    }
    @media #{$small-phone} {
      margin-top: 0px;
    }

    .img-dx{
      position: relative;
    }



  //   .box-home{
  //     top: 50%;
  //     position: relative;
  //     text-align: center;
  //     @include transform(translateY(50%));
  //     .logo-home{
  //       width: 300px;
  //     }
  //
  //
  //       h1 {
  //         color: $tortora;
  //         font-family: Cormorant Garamond,serif;
  //         font-size: 65px;
  //         position: relative;
  //         text-align: center;
  //         padding-bottom: 0px;
  //         line-height: 70px;
  //         font-weight: 300;
  //         @media #{$small-phone} {
  //           font-size: 50px;
  //         }
  //
  //       }
  //     }
  //   }
  }
}
