/******* Menu *******/
.navbar-wrapper {
  background-color: transparent;
  position: relative;
  color: $white;
  display: block;
  z-index: 999 !important;
  -webkit-transition: all .6s ease-in-out;
  @media #{$undermd} {
    height: 60px;
  }

}

.sticky-wrapper{
  height: 302px !important;
  @media #{$undermd} {
    height: 302px !important;
  }
  @media #{$undertablet} {
    height: 180px !important;
  }
}

.navbar{
  margin-bottom: 0px !important;
}

.navbar-header{
  left: 50%;
  position: relative;
  -webkit-transition: all .6s ease-in-out;
}

.navbar-brand {
  float: left;
  height: auto;
  padding: 10px 0;
  font-size: 18px;
  line-height: 20px;
  @media #{$undermd} {
    float: right;
  }
}

.navbar-nav>li {
  @media #{$undermd} {
    float: none;
  }
}

.navbar>.container-fluid .navbar-brand, .navbar>.container .navbar-brand {
    margin-left: 0px !important;
    margin-bottom: 0px !important;
}

.navbar-brand{
  img{
    width: 280px;
    -webkit-transition: all .6s ease-in-out;
    @media #{$underdesktop} {
      width: 250px;
    }
    @media #{$undermd} {
      width: 150px;
    }
    @media #{$small-phone} {
      width: 100px;
    }
  }
}

.navbar-right {
  float: right!important;
  margin-right: 100px !important;
  margin-top: 100px;
  right: 50%;
  -webkit-transition: all .6s ease-in-out;
  @media #{$desktop-xl} {
    margin-right: 50px !important;
  }
  @media #{$under-desktop-xl} {
    margin-right: 30px !important;
  }
  @media #{$underdesktop} {
    margin-right: 0px !important;
  }
}

.navbar-collapse{
  margin: 0 30px;
}

.navbar {
  font-family: 'Assistant', serif;
  position: relative;
  border-radius: 0;
  min-height: 200px;
  -webkit-transition: all .6s ease-in-out;

  @media #{$undermd} {
    margin: 0 10px;
    min-height: 80px;
  }
  @media #{$small-phone} {
    margin: 0 10px;
    min-height: 60px;
  }
}

.is-sticky{
  -webkit-transition: all .6s ease-in-out;
  .stuckMenu{
    background: $black;
    height: 90px;
    width: 100% !important;
    -webkit-transition: all .6s linear;
    @media #{$undertablet} {
      height: 60px;
    }
    li a,  li span {
      color: $white;
    }
    .navbar{
      min-height: 80px;
      @media #{$undertablet} {
        min-height: 60px;
      }
    }
    li.active > a, li.active > a:focus, .nav > li.active > a:hover {
      background-color: transparent !important;
      color: $orange !important;
      text-decoration: none !important;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
    }
    .navbar-right {
      float: right!important;
      margin-right: 10px !important;
      margin-top: 10px;
      -webkit-transition: all .6s ease-in-out;
      left: 0;

      @media #{$undermd} {
        left: 50%;
      }
    }
    .navbar-header{
      left: 0%;
      position: relative;
      -webkit-transition: all .6s ease-in-out;
    }
    .navbar {
      font-family: 'Assistant', serif;
      position: relative;
      border-radius: 0;
      min-height: 80px;
      -webkit-transition: all .15s ease-in-out;

      @media #{$undermd} {
        margin: 0 10px;
        min-height: 80px;
      }
      @media #{$undertablet} {
        min-height: 60px;
      }
      @media #{$small-phone} {
        margin: 0 10px;
        min-height: 60px;
      }
    }

    .navbar-brand{
      img{
        width: 150px;
        -webkit-transition: all .6s ease-in-out;

        @media #{$undermd} {
          width: 150px;
        }
        @media #{$undertablet} {
          width: 100px;
        }
      }
    }
  }
  #navigation-toggle span.nav-line {
    display: block;
    height: 3px;
    width: 20px;
    margin-bottom: 4px;
    background: $white!important;
    top: -10px;
  }
  #navigation-toggle{
    top: -10px;

  }

}

.menu {
    position: relative;
    z-index: 9;
    text-align: center;
    display: inline;

    li {
      text-align: center;
      padding: 0;
      display: inline-block;
      margin: 0 5px;
    }

    li a,  li span {
      color: $black;
      text-decoration: none;
      font-size: 20px;
      padding: 0 3px 0 3px;
      line-height: 35px;
      margin: 5px 0;
      font-family: 'Assistant', serif;
      font-weight: 800;
      text-transform: uppercase;
      border-bottom:1px dashed transparent;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
    }

    li.active > a, li.active > a:focus, .nav > li.active > a:hover {
        background-color: transparent !important;
        color: $orange !important;
        text-decoration: none !important;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
    }

    li a:hover, li a:focus {
      color: $orange;
      background-color: transparent;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
    }

    li a:active {
        background-color: transparent;
    }

    li:first-child a:after{
      content: "-";
      left: 8px;
      position: relative;
      top:-1px;

      @media #{$undermd} {
        display: none;
      }

    }
}

.social{
  display: inline;
  position: relative;

  .menu-social{
    margin-left: 30px;
  }

  li a{
    padding: 2px;
    margin-top: 10px;
    background: transparent;
  }
  li a i, li span i {
      border: 1px solid $black;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      font-size: 20px;
      height: 30px;
      letter-spacing: -.1em;
      line-height: 30px;
      margin-right: 5px;
      margin-top: -10px;
      text-align: center;
      width: 30px;
      color: #000;
      background: #908475;
      border-bottom: none;
  }
  li a i:hover {
    display: inline-block;
    font-size: 20px;
    height: 30px;
    letter-spacing: -.1em;
    line-height: 30px;
    margin-right: 5px;
    margin-top: -10px;
    text-align: center;
    width: 30px;
    color: black;
    background: $white;
  }

  li a:hover{
    background: transparent;
    border-bottom: none;
  }

  li a:hover:before,li a:focus, li.active a:before {
    background: none;
    content: "";
  }
}

.dropdown{ display:block !important; }

.menu .menunav ul {
    display: none;
    padding: 5px 0;
    position: absolute;
    top: 50px;
    width: 200px;
    z-index: 9999;
}

.menu li:hover ul {
    display: block;
}

/*** Menu responsive Overlay ***/
#navigation-toggle span.nav-line {
    display: block;
    height: 3px;
    width: 20px;
    margin-bottom: 4px;
    background: $black!important;
    @media #{$undermd} {
      background: $white !important;

    }
}

button#navigation-toggle {
  background-color: transparent !important;
  display: none;
  padding: 10px;
  display: inline-block;
  position: relative;
  float: right;
  transform: translateY(50%);
  top:-10px;

  @media #{$undermd} {
    display: block;
    float: left;
    background: $black !important;
    padding: 10px 10px 8px;
    border-radius: 50px;

  }

  span{
    background: $white;
    color: $white;
  }
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: $black;
  opacity: 0.98;

  ul li a:before {
    background: transparent;
    content: "";
    -moz-background-size: 50%;
    background-size: 50%;
    position: absolute;
    left: -20px;
    height: 35px;
    width: 35px;
    top: 10px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
  }

  ul li a:hover:before {
    background: url(../images/frecce_dx.png) no-repeat;
    content: "";
    -moz-background-size: 50%;
    background-size: 50%;
    position: absolute;
    left: -4px;
    height: 35px;
    width: 35px;
    top: 10px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
  }
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 80px;
	height: 80px;
	position: absolute;
	right: 20px;
	top: 20px;
	overflow: hidden;
	border: none;
	background: url(../images/close.png) no-repeat center center;
	text-indent: 200%;
	color: transparent;
	outline: none;
	z-index: 100;
}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 50%;
	height: 60%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: auto;
  position: relative;
  width: 100%;
  top: 30%;
  @include transform(translateY(-50%));
  @media #{$undermd} {
    left: 50%;
    @include transform(translateX(-50%));
  }
}

.overlay ul li {
	display: block;
	height: auto;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
  margin: 20px 0;
}

.overlay ul li a {
	font-size: 30px;
	font-weight: 300;
  display: inline-block;
	color: $white;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;
  border-bottom: 1px dashed transparent;
}

.overlay ul li a:hover,
.overlay ul li a:focus {
	color: $orange;
}

/* Effects */
.overlay-slidedown {
	visibility: hidden;
	-webkit-transform: translateY(-200%);
	transform: translateY(-200%);
  -webkit-transition: -webkit-transform 0.1s ease-in-out, visibility 0s 0.4s;
	transition: transform 0.1s ease-in-out, visibility 0s 0.4s;
}

.overlay-slidedown.open {
	visibility: visible;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
	-webkit-transition: -webkit-transform 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out;
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}

//Breadcrumbs
#crumbs{
  padding: 5px 0 5px 2px;
  a{
    color: $white;
    text-decoration: none;
    font-weight: 200;
    font-size: 16px;
  }

  a:hover{
    color: $black;
    text-decoration: none;
  }

  span{
    font-size: 16px;
    color: $black;
  }
}
