.slick-slide{
  background: transparent;
  img{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -10;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}

.slider-corsi{
  .slick-prev,
  .slick-next {
      font-size: 0;
      position: absolute;
      bottom: 20px;
      color: $black;
      border: 0;
      background: none;
      z-index: 1;
  }

  .slick-prev {
      left: 20px;
  }

  .slick-prev:before {
    font-family: FontAwesome;
    content: "\f053";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    color: $black;
    font-size: 40px;
    @media #{$undermd} {
      font-size: 25px;
    }
  }

  .slick-next {
      right: 20px;
      text-align: right;
  }

  .slick-next:before {
    font-family: FontAwesome;
    content: "\f054";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    color: $black;
    font-size: 40px;
    @media #{$undermd} {
      font-size: 25px;
    }
  }

  .slick-prev:hover:before,
  .slick-next:hover:before {
      color: $orange;
      font-size: 40px;
      @media #{$undermd} {
        font-size: 25px;
      }
  }
}


// .slick-active{
//   background: red !important;
// }
//
// .slick-current
// {
//   background: blue !important;
// }


//
// .slider-corsi{
//   .slick-prev, .slick-next {
//       font-size: 0;
//       line-height: 0;
//       position: absolute;
//       top: 50%;
//       display: block;
//       height: 150px;
//       width: 90px;
//       padding: 0;
//       @include transform(translateY(-50%));
//       cursor: pointer;
//       color: transparent;
//       border: none;
//       outline: none;
//       background: transparent;
//       @media #{$undertablet} {
//         height: 60px;
//         width: 90px;
//       }
//   }
//
//   // .slick-next, .slick-next:hover, .slick-next:focus {
//   //     right: -80px;
//   //     background-image: url("../images/freccia_destra.png");
//   //     background-position: center;
//   //     background-repeat: no-repeat;
//   //     background-color: transparent;
//   //     z-index: 1;
//   //     @media #{$desktop} {
//   //       right: 0px;
//   //     }
//   //     @media #{$underdesktop} {
//   //       right: 0px;
//   //     }
//   //     @media #{$undermd} {
//   //       right: 25px;
//   //     }
//   //     @media #{$small-phone} {
//   //       right: -20px;
//   //       top: 120px;
//   //     }
//   // }
//   //
//   // .slick-prev, .slick-prev:hover, .slick-prev:focus {
//   //     left: -80px;
//   //     background-image: url("../images/freccia_sinistra.png");
//   //     background-position: center;
//   //     background-repeat: no-repeat;
//   //     background-color: transparent;
//   //     z-index: 1;
//   //     @media #{$desktop} {
//   //       left: 0px;
//   //     }
//   //     @media #{$underdesktop} {
//   //       left: 0px;
//   //     }
//   //     @media #{$undermd} {
//   //       left: 25px;
//   //     }
//   //     @media #{$small-phone} {
//   //       left: -20px;
//   //       top: 120px;
//   //     }
//   // }
//   .slick-next:before{
//     content: "" !important;
//   }
//   .slick-prev:before{
//     content: "" !important;
//   }
//   .slick-slide{
//     margin-top: 10px;
//     margin-left: 10px;
//   }
//   .slick-prev, .slick-next {
//       font-size: 0;
//       line-height: 0;
//       position: absolute;
//       top: 50%;
//       display: block;
//       height: 40px;
//       width: 40px;
//       padding: 0;
//       @include transform(translateY(-50%));
//       cursor: pointer;
//       color: transparent;
//       border: none;
//       outline: none;
//       background: transparent;
//       @media #{$undertablet} {
//         height: 40px;
//         width: 40px;
//       }
//   }
//
//   .slick-next, .slick-next:hover, .slick-next:focus {
//       right: -50px;
//       background-image: url(../images/icona_freccia_dx.png);
//       background-position: center;
//       background-repeat: no-repeat;
//       background-color: transparent;
//       background-size: 100% 100%;
//       z-index: 1;
//       @media #{$desktop} {
//         right: -50px;
//       }
//       @media #{$underdesktop} {
//         right: -50px;
//       }
//       @media #{$undermd} {
//         right: -50px;
//       }
//       @media #{$small-phone} {
//         right: 0px;
//       }
//   }
//
//   .slick-prev, .slick-prev:hover, .slick-prev:focus {
//       content:"";
//       left: -50px;
//       background-image: url(../images/icona_freccia_sx.png);
//       background-position: center;
//       background-repeat: no-repeat;
//       background-color: transparent;
//       background-size: 100% 100%;
//       z-index: 1;
//       @media #{$desktop} {
//         left: -50px;
//       }
//       @media #{$underdesktop} {
//         left: -50px;
//       }
//       @media #{$undermd} {
//         left: -50px;
//       }
//       @media #{$small-phone} {
//         left: 0px;
//       }
//   }
//
//
//   .slick-slider .slick-list, .slick-slider .slick-track {
//     left: 50%;
//     @include transform(translateX(-50%));
//   }
//
//   .slick-dots{
//     ul{
//       list-style: none;
//       li{
//         list-style: none;
//       }
//     }
//     top:0px;
//     position: relative;
//     button{
//       width: 10px;
//       height: 10px;
//       cursor: pointer;
//       background: $white;
//       border:1px solid $black;
//       border-radius: 10px;
//     }
//
//     button:hover{
//       width: 10px;
//       height: 10px;
//       cursor: pointer;
//       background: $black;
//       border:1px solid $black;
//       border-radius: 10px;
//     }
//
//     button:before {
//       opacity: .75;
//       color: transparent !important;
//     }
//
//
//     .slick-active{
//       ul{
//         li{
//           list-style: none;
//         }
//       }
//
//       button{
//         width: 10px;
//         height: 10px;
//         cursor: pointer;
//         background: $black;
//         border:1px solid $black;
//         border-radius: 10px;
//
//       }
//     }
//
//
//   }
//
//   // .slick-next:before {
//   //   position: a
//   //   content: "";
//   //     right: -50px;
//   //     background-image: url("../images/icona_freccia_destra.png");
//   //     background-position: center;
//   //     background-repeat: no-repeat;
//   //     background-color: transparent;
//   //     z-index: 1;
//   //     @media #{$desktop} {
//   //       right: 0px;
//   //     }
//   //     @media #{$underdesktop} {
//   //       right: 0px;
//   //     }
//   //     @media #{$undermd} {
//   //       right: 25px;
//   //     }
//   //     @media #{$small-phone} {
//   //       right: -20px;
//   //       top: 120px;
//   //     }
//   // }
//   //
//   // .slick-prev:before {
//   //   content: "";
//   //     left: -50px;
//   //     background-image: url("../images/icona_freccia_sinistra.png");
//   //     background-position: center;
//   //     background-repeat: no-repeat;
//   //     background-color: transparent;
//   //     z-index: 1;
//   //     @media #{$desktop} {
//   //       left: 0px;
//   //     }
//   //     @media #{$underdesktop} {
//   //       left: 0px;
//   //     }
//   //     @media #{$undermd} {
//   //       left: 25px;
//   //     }
//   //     @media #{$small-phone} {
//   //       left: -20px;
//   //       top: 120px;
//   //     }
//   // }
// }
