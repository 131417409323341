#contatti{
  position: relative;
  overflow-x: hidden;

  .gallery-contatti{
    position: relative;
    top:-60px;
    margin-bottom: -60px;
  }

  .content, .indirizzi{
    margin-left: 80px;
    position: relative;
    @media #{$underdesktop} {
      margin-left: 0px;
    }
    @media #{$undermd} {
      text-align: center;
      margin: 60px 20px;
    }
  }

  .titoli_simple:before{
    @media #{$underdesktop} {
      display: none;
    }
  }

  .div-scroll{
    position: relative;
    margin-left: 80px;
    margin-top: 90px;
    @media #{$underxl} {
      margin-top: 40px;
    }
    @media #{$underdesktop} {
      display: none;
    }
  }

  p{
    color: $orange;
    font-weight: 600 !important;
    font-size: 20px;
    text-transform: uppercase;

    @media #{$under-desktop-xl} {
      margin-top: 0px;
    }
  }

  .indirizzi{
    ul{
      margin-top: 30px;
      position: relative;
      padding: 0;
      @media #{$undermd} {
        text-align: center;
      }

      li{
        font-weight: 800;
        color: $black;
        margin: 40px 0;
        position: relative;
        padding-left: 60px;
        list-style: none;
        @media #{$under-desktop-xl} {
          margin: 30px 0;
        }
        @media #{$undermd} {
          margin: 20px 0;
          padding-left: 0;
        }

        a{
          color: $orange;
        }
      }

      li.indirizzo:before{
        content: url("../images/indirizzo.svg");
        display:block;
        position: absolute;
        width:40px;
        height:40px;
        left: 0;
        top: 0px;
        @media #{$undermd} {
          position: relative;
          display: inline-block;
          left: -20px;
          top: 20px;
        }
      }
      li.telefono:before{
        content: url("../images/telefono.svg");
        display:block;
        position: absolute;
        width:40px;
        height:40px;
        left: 0;
        top: -10px;
        @media #{$undermd} {
          position: relative;
          display: inline-block;
          left: -20px;
          top: 10px;
        }
      }
      li.mail:before{
        content: url("../images/email.svg");
        display:block;
        position: absolute;
        width:40px;
        height:40px;
        left: 0;
        top: -10px;
        @media #{$undermd} {
          position: relative;
          display: inline-block;
          left: -20px;
          top: 10px;
        }
      }
    }
  }

}

#dove-siamo{
  padding: 80px 0 0;
  text-align: left;

  .cont{
    @media #{$undermd} {
      text-align: center;
    }
  }

  .tablet{
    background: url('../images/tablet.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 700px;
    margin-top: 60px;
    position: relative;
    z-index: 2;
    @media #{$underdesktop} {
      margin-bottom: -53px;
    }
    @media #{$undermd} {
      margin-top: 0px;
      margin-bottom: -122px;
    }
    @media #{$small-phone} {
      background: transparent;
      height: auto;
      margin: 60px 0;
    }
  }

  .mappa{
    position: absolute;
    top: 43%;
    left: 49.9%;
    @include transform(translate(-50%, -50%));
    background: $white;
    width: 98%;
    height: 450px;
    width: 585px;
    z-index: 0;

    @media #{$underdesktop} {
      height: 380px;
      top: 44%;
      width: 500px;
    }
    @media #{$undermd} {
      height: 300px;
      top: 46%;
      width: 390px;
    }
    @media #{$undertablet} {
      height: 350px;
      width: 100%;
      top:0;
      @include transform(translate(-50%, 0%));
    }
    @media #{$small-phone} {
      position: relative;
      top:0;
      @include transform(translate(-50%, 0%));
    }

    iframe{
      position: relative;
      z-index:0;
      @media #{$underdesktop} {
        height: 380px;
        width: 500px;
      }
      @media #{$undermd} {
        height: 300px;
        width: 390px;
      }
      @media #{$undertablet} {
        height: 350px;
        width: 100%;
      }
    }
  }
}
