footer{
  color: $black;
  font-size: 16px;
  background: $black;
  position: relative;

  .img-logo-footer{
    max-width: 100px;
    @media #{$undermd} {
      position: relative;
      left: 50%;
      @include transform(translate(-50%));
    }
  }

  .contouter{
    margin: 10px 0;
  }

  .center-footer{
    padding: 10px 0;
    border-right:2px solid $orange;
    border-left:2px solid $orange;
    @media #{$undermd} {
      border-right:0px solid $orange;
      border-left:0px solid $orange;
      margin: 20px 0;
    }

  }
  .center-footer:after{
    @media #{$undermd} {
      background: $orange;
      width:100px;
      height: 2px;
      position: absolute;
      content: "";
      left: 50%;
      top:95px;
      @include transform(translateX(-50%));
    }
  }
  .center-footer:before{
    @media #{$undermd} {
      background: $orange;
      width:100px;
      height: 2px;
      position: absolute;
      content: "";
      left: 50%;
      top:-5px;
      @include transform(translateX(-50%));
    }
  }

  .social{
    top: 10px;
    text-align: right;
    @media #{$undermd} {
      text-align: center;
      margin-top: 20px;
      display: block;
      margin-bottom: 20px;
    }

    a{
      border: 1px solid $black;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      font-size: 20px;
      height: 30px;
      letter-spacing: -.10em;
      line-height: 25px;
      margin-right: 5px;
      margin-top: -10px;
      text-align: center;
      width: 30px;
      color: $white;
      background: $black;
    }
    a:hover{
      -moz-border-radius: 50%;
      display: inline-block;
      font-size: 20px;
      height: 30px;
      letter-spacing: -.10em;
      line-height: 25px;
      margin-right: 5px;
      margin-top: -10px;
      text-align: center;
      width: 30px;
      color: $orange;
      font-weight: 400;
    }
  }
  strong{
    font-weight: 500;
  }
  span{
    font-weight: 200;
    letter-spacing: 1px;
    color: $white;
  }
  small{
    position: relative;
    text-align: center;
  }

  a{
    color: $orange;
    font-weight: 400;
    text-decoration: none;
  }
  a:hover{
    font-weight: 400;
    color: $white;
  }


}

.copyright{
  color: $white;
  padding: 0px 0 0;
  font-size: 8px;
  background: $black;
  text-align: center;

  small{
    font-size: 12px;
    letter-spacing: 1px;
  }

  a{
    color: $white;
    font-weight: 200;
    font-size: 12px;
    text-decoration: none;
  }
  a:hover{
    font-size: 12px;
    color: $orange;
    font-weight: 200;
  }
}

footer:before{
  background: url("../images/sfondo_sito.png") no-repeat;
  content: "";
  background-size: 50%;
  position: absolute;
  left: 50%;
  height: 50px;
  width: 50px;
  top: -60px;
  margin: 0 12px;
  @include transform(translateX(-50%));
}
